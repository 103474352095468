window.$ = window.jQuery = require('jquery'); // required for bootstrap
window.Popper = require('popper.js'); // required for tooltip, popup...

import 'bootstrap';
import 'slick-carousel';

import './modules';

var MYLANGUAGEKEY = 'languageId';
export var STOREIDKEY = 'storeId';
export var STORENUMBER = 'storeNumber';
export var STORECODE = 'storeCode';
export var STORENAMEKEY = 'storeName';
export var STOREINFOKEY = 'storeInfo';
export var lang = getCookie(MYLANGUAGEKEY);
var flyerId = '0';

/*<![CDATA[*/
var defaultLanguage = /*[[${languageDefault}]]*/ 'fr';
/*]]>*/


$('document').ready(function(){
    //SET DEFAULT LANG
    if (lang == null) {
        var navLang = navigator.language;
        lang = navLang.substring(0, 2);
    }

    injectDIScriptForNewsletter();
    setNewsletter();

    showMyStore();

    // STYLEGUIDE

    if ($('body').hasClass('styleguide')) {
        console.log('You are in the styleguide')
    }

    //STORE SELECTION COOKIE
    if ($('#setStore').length > 0) {
        let storeId = $('#setStore').data('store-id');
        let storeNumber = $('#setStore').data('store-number');
        let storeCode = $('#setStore').data('store-code');
        let storeName = $('#setStore').data('store-name');
        let storeInfo = $('#setStore').data('store-info');

        let storeSelected = getCookie(STORECODE);

        if (storeCode == storeSelected) {
            $('#setStore').addClass('d-none');
        } else {
            $('#setStore').on('click', function () {
                setCookie(STOREIDKEY, storeId);
                setCookie(STORENUMBER, storeNumber);
                setCookie(STORECODE, storeCode);
                setCookie(STORENAMEKEY, storeName);
                setCookie(STOREINFOKEY, storeInfo);
                $(this).addClass('d-none');
                showMyStore();
            });
        }
    }

    //FLIPP FEATURED PRODUCTS
    var $featuredProducts = $('#featured-products');
    if ($featuredProducts.length > 0){
        var lang = getCookie(MYLANGUAGEKEY);
        if(lang == null) {
            lang = 'fr';
        }
        var defaultStoreCode = getCookie(STORECODE);
        var myStoreCode = getCookie(STORECODE);
        var flyerStoreCode = (myStoreCode != null) ? myStoreCode : defaultStoreCode;
        var myStoreNumber = getCookie(STORENUMBER);


        // Get the id of the flyer to display the associated products
        $.ajax({
            type: 'GET',
            async: false,
            url: flyerApiUrl + 'Flyers/' + myStoreNumber + '/' + lang,
            headers: {
                'Ocp-Apim-Subscription-Key': subscriptionKey,
                banner: bannerId
            },
            dataType: 'json',
            success: function (data) {
                let deal = null;
                if(data && data.flyers[0])
                    deal = data.flyers[0].title;

                //Call to get the flyer's associated products
                callTCForProducts(deal, myStoreNumber);
            }
        });
    }

    //TOOLTIPS
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    });

    //LANGUAGE SWITCHER
    $(function () {
        $('[data-lang]').each(function() {
            $(this).on('click', function(e) {
                setCookie(MYLANGUAGEKEY, $(this).data('lang'));
            });
        })
    });

    //FOCUS ON FIRST FIELD OF A FORM IF ERRORS
    $('form').find('.error').first().parent().find('.form-control').focus();

    if ($('#flipp-container').length) {
        var lang = getCookie('languageId');
        var defaultStoreCode = (lang =='fr') ? '103':'106';
        var myStoreCode = getCookie('storeCode');
        var flyerStoreCode = (myStoreCode != null) ? myStoreCode : defaultStoreCode
        $('#flipp-store-code').val(myStoreCode);

        var flippContainer = document.querySelector("#flipp-container");
        var storefront = new Flipp.Storefront({
            accessToken: "32555571637e74352a2981f4cf3caf0e",
            merchantId: "3372",
            merchantNameIdentifier: "marcherichelieu",
            locale: lang+'-CA'
        });
        storefront.renderStorefront(flippContainer);
        storefront.updateStoreCode(flyerStoreCode);

    }
});

function newsletterFormObserver() {
    let errorContainerDivList = $('.DialogInsightErrorContainer'),
    options = {
        childList: true
    },
    observer = new MutationObserver(mCallback);

    if (typeof errorContainerDivList !== 'undefined' && errorContainerDivList) {
        for (let i = 0; i < errorContainerDivList.length; i++) {
            observer.observe(errorContainerDivList[i], options);
        }
    }
}

function mCallback(divErrorList) {
    for (let i = 0; i < divErrorList.length; i++) {
        if ($('.DialogInsightErrorContainer').children().length > 0) {
            $('.DialogInsightErrorContainer').find('.DialogInsightErrorContents').addClass('errors-field-form');
            $('.DialogInsightGlobalErrors').children().addClass('errors-field-form');
        }
    }
}

function setCookie(cookieKey, cookieValue) {
    var d = new Date();
    d.setTime(d.getTime() + 10*365*24*60*60*1000); //10 years
    document.cookie = cookieKey + '=' + cookieValue + ';path=/ ;expires='+d.toGMTString()+';'
}

function getCookie(cookieKey) {
    var cookieKey = cookieKey + '=';
    var ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(cookieKey) == 0) return c.substring(cookieKey.length, c.length);
    }
    return null;
}

function showMyStore() {
    var myStoreId = getCookie('storeId');
    var myStoreNumber = getCookie('storeNumber');
    var myStoreCode = getCookie('storeCode');
    var myStoreName = getCookie('storeName');
    var myStoreInfo = getCookie('storeInfo');

    if (myStoreName == null || myStoreName == 'undefined') {
        //SET DEFAULT QUEBEC STORE
        var defaultStore = $('#default-store');

        myStoreId = '8';
        myStoreNumber = '20004';
        myStoreCode = 'R107';
        myStoreName = 'Marché Richelieu - Marché Chauvin';
        myStoreInfo = '7890 Rue St-Denis, Montréal, Québec 514-271-7367';
        setCookie(STOREIDKEY, myStoreId);
        setCookie(STORENUMBER, myStoreNumber);
        setCookie(STORECODE, myStoreCode);
        setCookie(STORENAMEKEY, myStoreName);
        setCookie(STOREINFOKEY, myStoreInfo);
    }

    //INJECT STORE INFORMATION ON HEADER
    $('#selectedStoreCode').prop('value', myStoreCode);
    $('#selectedStoreInfo').html(myStoreName);
    $('#selectedStoreInfoAddress').html(myStoreInfo);
}

function callTCForProducts(deal, myStoreNumber) {
    var products, randomProducts;
    $.ajax({
        type: 'GET',
        url: flyerApiUrl + '/Pages/' + deal + '/' + myStoreNumber + '/' + lang,
        headers: {
            'Ocp-Apim-Subscription-Key': subscriptionKey,
            banner: bannerId
        },
        dataType: 'json',
        success: function (data) {
            products = getProductFromTCResponse(data);
            randomProducts = getRandomProductsFromList(products, 10);
            displayProducts(randomProducts);

        },
        error: function (){
            $(".featured-products__wrapper").hide();
        },
        complete: function() {
            if (products != 'undefined' && products.length > 0) {
                createCarroussel();
            }
        }
    });
}

function displayProducts(data) {

    var currentDay = Date.now();
    var productsCode = [];

    $.each(data, function(index, element) {
        var validFrom = new Date(element.validFrom);

        //Afficher que les produits vedettes sont bien actifs et cacher les rabais à venir
        if(currentDay > validFrom.getTime() && productsCode.indexOf(element.sku) < 0) {
            productsCode.push(element.sku);

            var productTemplate = $('#product-template').html();
            var productTemplateSr = $('#product-template-sr').html();
            var productName = lang == "fr" ? element.productFr : element.productEn;

            var replacePrePricePart2 = lang == "fr" ? element.salePricePrefixFr : element.salePricePrefixEn;
            var replacePrePrice = lang == "fr" ? element.salePricePrefixFr : element.salePricePrefixEn;
            if(replacePrePrice && replacePrePrice.split(" ").length > 1) {
                if(lang == 'en') {
                    replacePrePricePart2 = replacePrePrice.split(" ")[1].replace("pour", "for");
                    replacePrePrice = replacePrePrice.split(" ")[0] + " " + replacePrePricePart2;
                }
            } else if(!replacePrePrice) {
                replacePrePrice = replacePrePrice ? replacePrePrice : "";
            }


            // split the lb and kg prices
            var productTextLb =  lang == "fr" ? element.promoUnitFr : element.promoUnitEn;
            productTextLb = productTextLb ? productTextLb : "";
            var delimiter = '/';
            var dollarDiscount, percentDiscount;
            if(productTextLb && productTextLb.split(delimiter).length > 1) {
                productTextLb = productTextLb.split(delimiter)[0] + delimiter + productTextLb.split(delimiter)[1];
            }

            if( lang != 'undefined' && lang == "fr") {
                dollarDiscount = '$ de rabais';
                percentDiscount = '% de rabais';
            } else if (lang != 'undefined' && lang == "en") {
                dollarDiscount = 'Discount of ';
                percentDiscount = 'Discount of ';
            }

            productTemplate = productTemplate.replace(new RegExp('__DESKTOP_IMG__', 'g'), element.productImage);
            productTemplate = productTemplate.replace(new RegExp('__ALT__', 'g'), productName);
            productTemplate = productTemplate.replace(new RegExp('__TITLE__', 'g'), productName);
            productTemplateSr = productTemplateSr.replace(new RegExp('__TITLE__', 'g'), productName);
            try{
                let price = lang == "en" ? element.salePrice : element.salePriceFr;
                if (price != null && price != '') {
                    productTemplate = productTemplate.replace(new RegExp('__TYPE_FR__', 'g'), '$');
                    productTemplate = productTemplate.replace(new RegExp('__TYPE_EN__', 'g'), '');
                    productTemplate = productTemplate.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + parseFloat(Math.round(price * 100) / 100).toFixed(2));
                    productTemplateSr = productTemplateSr.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + parseFloat(Math.round(price * 100) / 100).toFixed(2));
                } else if (element.dollars_off != null && element.dollars_off != '') {
                    productTemplate = productTemplate.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + parseFloat(Math.round(element.dollars_off * 100) / 100).toFixed(2));
                    productTemplateSr = productTemplateSr.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + parseFloat(Math.round(element.dollars_off * 100) / 100).toFixed(2));
                    if( lang != 'undefined' && lang == "fr") {
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_FR__', 'g'), dollarDiscount);
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_EN__', 'g'), '');
                    } else if (lang != 'undefined' && lang == "en") {
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_EN__', 'g'), dollarDiscount);
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_FR__', 'g'), '$');
                    }
                } else if (element.percent_off != null && element.percent_off != '') {
                    if( lang != 'undefined' && lang == "fr") {
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_FR__', 'g'), percentDiscount);
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_EN__', 'g'), '');
                    } else if (lang != 'undefined' && lang == "en") {
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_EN__', 'g'), percentDiscount);
                        productTemplate = productTemplate.replace(new RegExp('__TYPE_FR__', 'g'), '%');
                    }
                    productTemplate = productTemplate.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + element.percent_off);
                    productTemplateSr = productTemplateSr.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + element.percent_off);
                }
            }catch(err){
                productTemplate = productTemplate.replace(new RegExp('__TYPE_EN__', 'g'), '');
                productTemplate = productTemplate.replace(new RegExp('__TYPE_FR__', 'g'), '');
                productTemplate = productTemplate.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + price);
                productTemplateSr = productTemplateSr.replace(new RegExp('__PRICE__', 'g'), replacePrePrice + ' ' + price);
            }

            productTemplate = productTemplate.replace(new RegExp('__UNIT__', 'g'), productTextLb);
            productTemplateSr = productTemplateSr.replace(new RegExp('__UNIT__', 'g'), productTextLb);

            $('.featured-products__list').append(productTemplate);
            $('#featured-products-sr').append(productTemplateSr);
        }
    });
}

function createCarroussel() {
    //Création du carrousel des produits vedettes
    $('#featured-products').slick({
        infinite: true,
        speed: 1000,
        dots: true,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    autoplay: false
                }
            },
            {
                breakpoint: 575,
                settings: {
                    dots: false,
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    autoplay: true,
                    autoplaySpeed: 3000
                }
            }
        ]
    });

    var $status = $('.paging-info');
    var $slickElement = $('#featured-products');

    $slickElement.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = (currentSlide ? currentSlide : 0) + 1;
        $status.text((i) + '/' + (slick.slideCount));
    });
}

function getProductFromTCResponse(data){
    let products = [];
    if(data && data[0].blocks){
        let blocks = data[0].blocks;
        blocks.forEach(function(block){
            if(block.products){
                block.products.forEach(function(product){
                    if(product.productFr && !product.sku.toUpperCase().includes("INBLOCKTC")) {
                        products.push(product);
                    }
                });
            }
        });
    }
    return products;
}

function getRandomProductsFromList(products, numberOfProduct) {
    let randomProducts = [];
    let indexUsed = [];
    let randomIdx = -1;
    for(var i=0; i < numberOfProduct; i++) {
        randomIdx =  Math.floor(Math.random() * (numberOfProduct - 0 + 1));
        if(indexUsed.indexOf(randomIdx) == -1){
            randomProducts.push(products[randomIdx]);
            indexUsed.push(randomIdx);
        } else {
            i--;
        }
    }
    return randomProducts;
}


function injectDIScriptForNewsletter() {
    var init = setInterval(function(){
        if (typeof OneTrust !== 'undefined') {
            console.log("Onetrust not null")
            clearInterval(init);
            OneTrust.OnConsentChanged(function () {
                location.reload();
            });

            var gads = document.createElement('script');
            gads.type = 'text/javascript';
            gads.src = 'https://cdn.dialoginsight.com/DI/Forms/DI.Forms.Optin-package-1.3.min.js';
            gads.async = true;

            if (!oneTrustRequired()) {
                gads.class = "optanon-category-C0004";
                gads.type = "text/plain";
            }

        }
        var node = document.getElementsByTagName('script')[0];
        node.parentNode.insertBefore(gads, node);

    }, 500);


}
function oneTrustRequired() {
    let categories = ['C0004', 'C0002', 'C0003'];
    var oneTrustRequired = true;
    for (var i = 0; i < categories.length; i++) {
        if (!OnetrustActiveGroups.includes(categories[i])) {
            oneTrustRequired = false;
        }
    }

    return oneTrustRequired;
}

function setNewsletter(){
    setTimeout(function() {
            var newsletterFormUrl = lang === "fr" ? "newsletter-form.html" : "en/newsletter-form.html";
            $("#newsletter-wrapper").load(newsletterFormUrl, function(){

                $(".close-newsletter-popup").click(function () {
                    setCookie('hide-newsletter-popup', true);
                });

                if(oneTrustRequired()) {
                    if (getCookie('hide-newsletter-popup') !== 'true') {
                        $('#btnNewsletterForm').trigger("click");
                    }

                    $('#btn-form-newletter').click(function () {
                        DI.Forms.Optin.Client.GetOptinForm('DIForm_624_5337').Submit(this);
                        setCookie('hide-newsletter-popup', true);
                        return false;
                    });
                    newsletterFormObserver();
                } else {
                    $(".DialogInsightForm")[0].innerHTML = displayOnetrustRequiredMessage();
                }
            });
    }, 600);

    function displayOnetrustRequiredMessage() {
        return lang === 'fr' ?
            "<p class='label-color-form message-onetrust-required'>Nous ne pouvons afficher ce contenu, car vous avez refusé l'utilisation des témoins (cookies) sur ce site Web. Pour rendre votre expérience optimale, veuillez activer les témoins de performance, personnalisation et marketing dans le <a class=\"showCookiePopUpBtn optanon-toggle-display\" href=\"javascript:function(){return true;}\">centre de préférences.</a></p>" :
            "<p class='label-color-form message-onetrust-required'>We are unable to display this content because you have refused the use of cookies on this website. To optimize your experience, please enable the performance, functional and marketing cookies in the <a class=\"showCookiePopUpBtn optanon-toggle-display\" href=\"javascript:function(){return true;}\">preferences center.</a></p>";
    }
}

