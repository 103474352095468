/*
  Automatically load and instantiates modules based on data-attributes
  specifying module file-names.
*/

// Emulate es6 Promise for IE11 support
require('es6-promise').polyfill();

export default window.addEventListener("load", function() {
    const moduleElements = document.querySelectorAll('[data-module]');

    for (var i = 0; i < moduleElements.length; i++) {

      const el = moduleElements[i];
      const name = el.getAttribute('data-module');
              
      import(`./${name}`).then(function(module) {            
          var Module = module.default;
          new Module(el);
      }).catch(err => {
        console.log('An error occurred while importing the module: ' + name);
        console.error(err);
      });
    }
});



/*
  Usage:
  ======

  html
  ----
  <button data-module="disappear">disappear!</button>

  js
  --
  // modules/disappear.js
  export default class Disappear {
    constructor(el) {
      el.style.display = none
    }
  }
*/
